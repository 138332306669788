// SliderBanner.js

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Autoplay } from 'swiper/modules';
import "swiper/css";
import Banner1 from '../assets/home/hme-bnr-1.jpg';
import Banner2 from '../assets/home/hme-bnr-2.jpg';
import Banner3 from '../assets/home/hme-bnr-3.jpg';

const SliderBanner = () => {
  // Function to handle form submission or other interactive logic
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  return (
    <section className="relative w-full mx-auto overflow-hidden h-[800px]">
      <Swiper
        loop
        spaceBetween={0}
        pagination={{ clickable: true }}
        modules={[Autoplay]}
        autoplay={{ delay: 4500 }}
        className="w-full h-full"
      >
        <SwiperSlide>
          <img src={Banner1} className="w-full h-full object-cover" alt="Slide 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Banner2} className="w-full h-full object-cover" alt="Slide 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Banner3} className="w-full h-full object-cover" alt="Slide 3" />
        </SwiperSlide>
      </Swiper>
      <div className="absolute top-0 left-0 w-full h-full bg-[#00000080] flex items-center justify-center z-10">
        <div className="container mx-auto px-7 lg:flex lg:justify-between lg:items-center h-full">
          <div className="text-white lg:w-1/2 lg:pr-8 h-max">
            <h1 className="text-white font-bold leading-[122%] wow fadeInDown frank w-full 2xl:text-[84px] xl:text-[68px] lg:text-[54px] sm:text-[39px] text-[25px] mt-6" data-wow-delay="0.3s">
              Green Orchid: Your Gateway to Tranquil Living
            </h1>
            <p className="text-white lg:mt-8 lg:text-xl sm:text-lg text-xs wow fadeInDown montserrat my-6" data-wow-delay="0.6s">
              Discover unmatched elegance and comfort at Green Orchid, where residential plots are nestled in the heart of nature, surrounded by lush greenery. Enjoy a tranquil living environment that blends seamlessly with the serene natural landscape.
            </p>
          </div>
          <div className="lg:w-1/2 lg:pl-8">
            <div className="bg-[#00000099] text-white rounded-2xl py-8 px-6 lg:px-8 2xl:py-14 wow slideInRight" data-wow-delay="0s">
              <h2 className="text-[26px] font-bold mb-4 lg:mb-5 2xl:mb-8 xl:text-[38px] lg:text-[34px] md:text-3xl encode wow shake">
                Book a Site Visit
              </h2>
              <center>
                <p id="success" className="text-green-500 font-bold hidden">Your visit sent. We will get back to you soon!</p>
                <p id="error" className="text-red-500 font-bold hidden">Something went wrong</p>
                <p id="wait" className="text-white font-bold hidden">Please wait...</p>
              </center>
              <form id="bannerForm" className="text-[#A1A1A1] text-[20px]" onSubmit={handleSubmit}>
                <div className="mb-6">
                  <input
                    type="text"
                    id="bannerName"
                    name="bannerName"
                    placeholder="Your Name"
                    className="w-full px-3 py-2 border-b-[1px] border-[#A2A2A2] bg-transparent lg:text-xl sm:text-lg text-base focus:outline-none focus:border-[#A2A2A2] wow slideInRight"
                    data-wow-delay="0.5s"
                    required
                  />
                </div>
                <div className="mb-6">
                  <input
                    type="email"
                    id="bannerEmail"
                    name="bannerEmail"
                    placeholder="Email Address"
                    className="w-full px-3 py-2 border-b-[1px] border-[#A2A2A2] bg-transparent lg:text-xl sm:text-lg text-base focus:outline-none focus:border-[#A2A2A2] wow slideInRight"
                    data-wow-delay="0.6s"
                    required
                  />
                </div>
                <div>
                  <input
                    type="tel"
                    id="bannerPhone"
                    name="bannerPhone"
                    placeholder="Phone Number"
                    className="w-full px-3 py-2 border-b-[1px] border-[#A2A2A2] bg-transparent lg:text-xl sm:text-lg text-base focus:outline-none focus:border-[#A2A2A2] wow slideInRight"
                    data-wow-delay="0.9s"
                    required
                  />
                </div>
                <div className="flex justify-center mt-8">
                  <div
                    className="g-recaptcha visitcaptcha"
                    data-sitekey="6LcpRxcqAAAAAJVTSx3F_SVNBqneWSm3rglfDdsf"
                    data-callback="recaptchaCallback1"
                  ></div>
                </div>
                <div className="mt-8">
                  <button
                    type="submit"
                    className="w-full bg-button py-2 px-3 sm:px-4 2xl:px-6 font-bold text-white bg-[#ED2024] rounded-2xl hover:bg-red-700 transition ease-in-out duration-500 text-sm sm:text-lg xl:text-base 2xl:text-lg wow fadeInUp"
                    data-wow-duration="0.9s"
                    data-wow-delay="1s"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="group mt-6 lg:mt-4 bg-button text-[#ED2024] rounded-2xl bg-white hover:text-white hover:bg-[#ED2024] transition ease-in-out duration-500 text-base lg:text-xl sm:text-lg py-2 px-2 sm:px-4 2xl:px-6 text-center font-bold wow fadeInUpBig" data-wow-duration="1.5s">
              <button onClick={() => window.location.href = 'tel:+918550887799'}>Phone +91 91347 47474</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderBanner;
