// WhyGreenOrchid.js

import React, { useState } from 'react';
import { Transition } from '@headlessui/react'; // For smooth transitions

const WhyGreenOrchid = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <section id="why-green-orchid" className="relative w-fullclassName bg-gray-50 py-16 lg:py-24">
      <div className="container mx-auto px-4 lg:px-8">
        <div className="flex flex-col lg:flex-row gap-12 lg:gap-24 items-center">
          {/* Content Section */}
          <div className="lg:w-1/2">
            <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-800 mb-6">
              Why Green Orchid
            </h2>
            <p className="text-lg lg:text-xl text-gray-700 mb-6">
              Green Orchid, brought to you by Best Properties in Bangalore, is an exclusive project featuring exceptional villa plots that offer a blank canvas for your dreams and ideas. These thoughtfully designed plots provide an ideal foundation for crafting your perfect home, blending modern amenities with the beauty of nature.
            </p>
            <button
              onClick={toggleShowMore}
              className="inline-flex items-center px-6 py-3 bg-indigo-600 text-white rounded-lg shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300 ease-in-out"
            >
              {showMore ? 'Show Less' : 'Show More'}
              <svg
                className={`w-5 h-5 ml-2 transform transition-transform duration-300 ${showMore ? 'rotate-180' : ''}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            <Transition
              show={showMore}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="mt-4 p-6 bg-white border border-gray-200 rounded-lg shadow-md"
            >
              <p className="text-gray-600">
                Green Orchid’s exclusive amenities include a state-of-the-art fitness center, a serene spa, a swimming pool, and a children’s play area. The community also offers walking trails, a clubhouse, and 24/7 security for a comprehensive living experience. Our commitment to sustainability is reflected in our eco-friendly designs and green spaces.
              </p>
            </Transition>
          </div>
          {/* Details Section */}
          <div className="lg:w-1/2 bg-white p-8 rounded-lg shadow-lg">
            <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-4">
              Project Highlights
            </h3>
            <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
              <li>6.5 Acres</li>
              <li>114 Plots</li>
              <li>15+ Amenities</li>
              <li>RERA Registered</li>
              <li>BMRDA/APA Approved</li>
              <li>Prime Location</li>
            </ul>
            <p className="text-gray-700">
              Investing in Green Orchid provides a tranquil living environment and assures you of a sound investment in a growing neighborhood. With a focus on quality and community, Green Orchid stands out as a premier choice for luxury and value in Bangalore's real estate market.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyGreenOrchid;
