import React from 'react';
import Image1 from '../assets/home/green-orchid-villas.jpg'; // Example images
import Image2 from '../assets/home/sunny-meadows-apartments.jpg';
import Image3 from '../assets/home/urban-heights.jpg';

const CompletedPlotProjects = () => {
  // Updated data for completed plot projects
  const plotProjects = [
    {
      title: 'Green Orchid Development',
      description: 'A completed plot project with various residential and commercial plots.',
      image: Image1,
      location: 'Vengadamangalam',
      plots: 69,
      totalSqFeet: '76,958',
      year: 2022,
    },
    {
      title: 'Sunny Meadows Land',
      description: 'A fully developed land area with a focus on residential plots and community spaces.',
      image: Image2,
      location: 'Sunnyville',
      plots: 120,
      totalSqFeet: '90,000',
      year: 2021,
    },
    {
      title: 'Urban Heights Plot Expansion',
      description: 'An expansive plot project featuring both residential and commercial spaces.',
      image: Image3,
      location: 'Downtown City',
      plots: 150,
      totalSqFeet: '150,000',
      year: 2023,
    },
    {
      title: 'Green Orchid Development',
      description: 'A completed plot project with various residential and commercial plots.',
      image: Image1,
      location: 'Vengadamangalam',
      plots: 69,
      totalSqFeet: '76,958',
      year: 2022,
    },
    {
      title: 'Sunny Meadows Land',
      description: 'A fully developed land area with a focus on residential plots and community spaces.',
      image: Image2,
      location: 'Sunnyville',
      plots: 120,
      totalSqFeet: '90,000',
      year: 2021,
    },
    {
      title: 'Urban Heights Plot Expansion',
      description: 'An expansive plot project featuring both residential and commercial spaces.',
      image: Image3,
      location: 'Downtown City',
      plots: 150,
      totalSqFeet: '150,000',
      year: 2023,
    },
  ];

  return (
    <section id="completed-plot-projects" className="bg-gray-50 py-16 lg:py-24">
      <div className="container mx-auto px-4 lg:px-8">
        <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-800 mb-12 text-center">
          Completed Plot Projects
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {plotProjects.map((project, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-72 object-cover rounded-t-lg mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-800 mb-2">{project.title}</h3>
              <p className="text-gray-600 mb-4">{project.description}</p>
              <div className="text-gray-700 mb-4">
                <p><strong>Location:</strong> {project.location}</p>
                <p><strong>No of Plots:</strong> {project.plots}</p>
                <p><strong>Total Sq Feet:</strong> {project.totalSqFeet}</p>
                <p><strong>Year:</strong> {project.year}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CompletedPlotProjects;
