import React from 'react';
import GalleryImage1 from '../assets/home/galleryimage1.jpg'; // Replace with the path to your image
import GalleryImage2 from '../assets/home/galleryimage4.jpg'; // Replace with the path to your image
import GalleryImage3 from '../assets/home/galleryimage5.jpg'; // Replace with the path to your image

const Gallery = () => {
    const galleryImages = [
        {
            src: GalleryImage1,
            alt: 'Landscaped Plot'
        },
        {
            src: GalleryImage2,
            alt: 'Lush Green Surroundings'
        },
        {
            src: GalleryImage3,
            alt: 'Modern Amenities'
        }
    ];

    return (
        <section id="gallery" className="relative w-full py-12 bg-gray-100">
            <div className="container mx-auto px-4 lg:px-8 text-center">
                {/* Introductory Content */}
                <div className="mb-12">
                    <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-8">
                        Gallery
                    </h2>
                    <p className="text-base lg:text-lg text-gray-700 mb-8">
                        Visit our gallery to see stunning images of our beautifully landscaped plots, lush green surroundings, secure gated entry, and modern amenities. Imagine your future home at Green Orchid, where excellence and sustainability meet.
                    </p>
                </div>

                {/* Gallery Images */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {galleryImages.map((image, index) => (
                        <div key={index} className="relative overflow-hidden rounded-lg shadow-md">
                            <img
                                src={image.src}
                                alt={image.alt}
                                className="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-105"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Gallery;
