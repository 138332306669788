import React from 'react';
import AmenitiesImage from '../assets/home/amenities1.jpg'; // Replace with the path to your image
import SecurityImage from '../assets/home/24--7-security2b.png'; // Replace with the path to your image
import MeditationImage from '../assets/home/meditation-lawn-b.png'; // Replace with the path to your image
import ParkImage from '../assets/home/sr-citizen-park b.png'; // Replace with the path to your image
import GreeneryImage from '../assets/home/green healthy b.png'; // Replace with the path to your image
import SportsImage from '../assets/home/gallery-hover5.png'; // Replace with the path to your image
import WaterManagementImage from '../assets/home/sustainable waters b.png'; // Replace with the path to your image

const Amenities = () => {
    const amenities = [
        {
            title: '24/7 Security',
            image: SecurityImage
        },
        {
            title: 'Meditation Lawn',
            image: MeditationImage
        },
        {
            title: 'Senior Citizen’s Park',
            image: ParkImage
        },
        {
            title: 'Green and Healthy Surroundings',
            image: GreeneryImage
        },
        {
            title: 'Sports, Fitness and Recreation Areas',
            image: SportsImage
        },
        {
            title: 'Sustainable Water Management',
            image: WaterManagementImage
        }
    ];

    return (
        <section id="amenities" className="relative w-full py-12 bg-gray-100">
            <div className="container mx-auto px-4 lg:px-8 flex flex-col lg:flex-row">
                {/* Image Section */}
                <div className="lg:w-1/2 flex items-center justify-center mb-8 lg:mb-0">
                    <img
                        src={AmenitiesImage}
                        alt="Amenities Overview"
                        className="w-full h-auto object-cover rounded-lg shadow-md"
                    />
                </div>
                
                {/* Content Section */}
                <div className="lg:w-1/2 lg:pl-8">
                    <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-8">
                        Amenities
                    </h2>
                    <p className="text-base lg:text-lg text-gray-700 mb-8">
                        Discover Excellence with Green Orchid's Unmatched Amenities. Green Orchid redefines excellence at every step with its exceptional amenities. Our commitment to sustainability is evident through environment-friendly features like energy-efficient street lighting and rainwater harvesting systems for sustainable water management. Enjoy the convenience of underground electrical systems, stormwater drains, and water connections to each plot, ensuring a mess-free living experience.
                        <br /><br />
                        Our thoughtfully designed layouts include vaastu-compliant plots and five unique theme parks, offering a harmonious living environment. Safety is a priority with our 24-hour security system, and accessibility is ensured with a well-constructed 9-meter wide road. Experience unparalleled living at Green Orchid.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {amenities.map((amenity, index) => (
                            <div key={index} className="flex items-center space-x-4">
                                <div className="w-16 h-16 flex-shrink-0">
                                    <img
                                        src={amenity.image}
                                        alt={`Amenity ${index + 1}`}
                                        className="w-full h-full object-contain"
                                    />
                                </div>
                                <div className="flex-1">
                                    <p className="text-sm lg:text-base font-medium text-gray-700">{amenity.title}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Amenities;
