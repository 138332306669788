import React from 'react';
import MasterplanImage from '../assets/home/masterplan.jpg'; // Replace with the path to your image

const Masterplan = () => {
    return (
        <section id="key-highlights" className="relative w-full py-12 bg-gray-50">
            <div className="container mx-auto px-4 lg:px-8 text-center">
                <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-6">
                    Masterplan - Luxurious Living Amidst Lush Greenery
                </h2>
                <p className="text-base lg:text-lg text-gray-700 mb-6">
                    Discover your ideal haven with luxurious living amidst lush greenery and modern amenities at Green Orchid. Our project features a walking track, senior citizen’s park, open gym, kids play area, meditation lawn, and a gazebo. We also have an avenue plantation within our project premises, ensuring a serene and beautiful environment for all residents.
                </p>
                <img
                    src={MasterplanImage}
                    alt="Masterplan Overview"
                    className="w-full h-auto object-cover rounded-lg shadow-md"
                />
            </div>
        </section>
    );
};

export default Masterplan;
