// Header.js
import React, { useState } from 'react';
import Icon from '../assets/favicon.jpg';
import { LuDownload, LuX } from 'react-icons/lu'; // Import close icon

const Header = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleNavbarToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const handleDownloadBrochure = () => {
    // Add logic for downloading the brochure here
  };

  const handleScroll = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    
    if (targetElement) {
      const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
  
      const offset = 80; 
      const scrollToPosition = targetPosition - offset;
  
      window.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth'
      });
    }

    if (isNavbarOpen) {
      setIsNavbarOpen(false);
    }
  };
  

  return (
    <header className="header w-full sticky top-0 left-0 z-50 bg-white shadow-lg">
      <div className="container mx-auto px-7 py-3">
        <nav className="relative flex items-center justify-between lg:justify-between z-[11]">
          {/* Logo */}
          <div className="relative">
            <a href="/">
              <img
                src={Icon}
                alt="Greencity"
                title="Greencity"
                className="w-24 md:w-28 h-auto"
              />
            </a>
          </div>

          {/* Desktop Navigation Links */}
          <ul
            className="hidden lg:flex flex-grow justify-center space-x-6"
            id="nav-links"
          >
            <li>
              <a
                href="#why-green-orchid"
                onClick={(e) => handleScroll(e, 'why-green-orchid')}
                className="bookmark-menu nav-link py-2 text-center hover:text-[#ED2024] transition-colors duration-300"
              >
                Why Green Orchid
              </a>
            </li>
            <li>
              <a
                href="#project-overview"
                onClick={(e) => handleScroll(e, 'project-overview')}
                className="bookmark-menu nav-link py-2 text-center hover:text-[#ED2024] transition-colors duration-300"
              >
                Overview
              </a>
            </li>
            <li>
              <a
                href="#upcoming-projects"
                onClick={(e) => handleScroll(e, 'upcoming-projects')}
                className="bookmark-menu nav-link py-2 text-center hover:text-[#ED2024] transition-colors duration-300"
              >
                Upcoming Projects
              </a>
            </li>
            <li>
              <a
                href="#completed-projects"
                onClick={(e) => handleScroll(e, 'completed-projects')}
                className="bookmark-menu nav-link py-2 text-center hover:text-[#ED2024] transition-colors duration-300"
              >
                Completed Projects 
              </a>
            </li>
            <li>
              <a
                href="#masterplan"
                onClick={(e) => handleScroll(e, 'masterplan')}
                className="bookmark-menu nav-link py-2 text-center hover:text-[#ED2024] transition-colors duration-300"
              >
                Master Plan
              </a>
            </li>
            <li>
              <a
                href="#amenities"
                onClick={(e) => handleScroll(e, 'amenities')}
                className="bookmark-menu nav-link py-2 text-center hover:text-[#ED2024] transition-colors duration-300"
              >
                Amenities
              </a>
            </li>
            <li>
              <a
                href="#gallery"
                onClick={(e) => handleScroll(e, 'gallery')}
                className="bookmark-menu nav-link py-2 text-center hover:text-[#ED2024] transition-colors duration-300"
              >
                Gallery
              </a>
            </li>
            <li>
              <a
                href="#location-map"
                onClick={(e) => handleScroll(e, 'location-map')}
                className="bookmark-menu nav-link py-2 text-center hover:text-[#ED2024] transition-colors duration-300"
              >
                Location
              </a>
            </li>
          </ul>

          {/* Download Brochure Button */}
          <a
            onClick={handleDownloadBrochure}
            className="cursor-pointer group hidden lg:flex items-center bg-button px-6 py-3 rounded-2xl border bg-indigo-600 text-white hover:bg-indigo-700 transition ease-in-out duration-500"
          >
            <div className="mr-3 montserrat">Download Brochure</div>
            <LuDownload className="block group-hover:hidden" />
            <LuDownload className="group-hover:block hidden" />
          </a>

          {/* Mobile Menu Toggle Button */}
          <div className="lg:hidden flex items-center">
            <div
              className="toggle-button relative cursor-pointer"
              onClick={handleNavbarToggle}
            >
              <span className="bar block bg-gray-600 h-1 w-6 mb-1"></span>
              <span className="bar block bg-gray-600 h-1 w-6 mb-1"></span>
              <span className="bar block bg-gray-600 h-1 w-6"></span>
            </div>
          </div>

          {/* Mobile Menu */}
          <ul
            className={`fixed top-0 left-0 w-full h-screen bg-white transition-transform duration-300 ease-in-out lg:hidden ${
              isNavbarOpen ? 'translate-x-0' : '-translate-x-full'
            } flex flex-col items-center justify-center`}
            id="nav-links"
          >
            {/* Close Button for Mobile */}
            {isNavbarOpen && (
              <button
                onClick={handleNavbarToggle}
                className="absolute top-4 right-4 text-gray-600"
              >
                <LuX className="text-3xl" />
              </button>
            )}
            <li>
              <a
                href="#why-green-orchid"
                onClick={(e) => handleScroll(e, 'why-green-orchid')}
                className="bookmark-menu nav-link py-2 text-center block hover:text-[#ED2024] transition-colors duration-300"
              >
                Why Green Orchid
              </a>
            </li>
            <li>
              <a
                href="#project-overview"
                onClick={(e) => handleScroll(e, 'project-overview')}
                className="bookmark-menu nav-link py-2 text-center block hover:text-[#ED2024] transition-colors duration-300"
              >
                Overview
              </a>
            </li>
            <li>
              <a
                href="#masterplan"
                onClick={(e) => handleScroll(e, 'masterplan')}
                className="bookmark-menu nav-link py-2 text-center block hover:text-[#ED2024] transition-colors duration-300"
              >
                Master Plan
              </a>
            </li>
            <li>
              <a
                href="#amenities"
                onClick={(e) => handleScroll(e, 'amenities')}
                className="bookmark-menu nav-link py-2 text-center block hover:text-[#ED2024] transition-colors duration-300"
              >
                Amenities
              </a>
            </li>
            <li>
              <a
                href="#gallery"
                onClick={(e) => handleScroll(e, 'gallery')}
                className="bookmark-menu nav-link py-2 text-center block hover:text-[#ED2024] transition-colors duration-300"
              >
                Gallery
              </a>
            </li>
            <li>
              <a
                href="#location-map"
                onClick={(e) => handleScroll(e, 'location-map')}
                className="bookmark-menu nav-link py-2 text-center block hover:text-[#ED2024] transition-colors duration-300"
              >
                Location
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
