import React from 'react';
import HighlightImage1 from '../assets/home/key1.png'; // Replace with the path to your image
import HighlightImage2 from '../assets/home/key2.png'; // Replace with the path to your image
import HighlightImage3 from '../assets/home/key3.png'; // Replace with the path to your image
import HighlightImage4 from '../assets/home/metrostation.png'; // Replace with the path to your image
import BackgroundImg from '../assets/home/amenities-bg.jpg';

const KeyHighlights = () => {
    const highlights = [
      {
        title: 'Great Connectivity with a Metro Station nearby',
        image: HighlightImage1
      },
      {
        title: 'Comprehensive List of Educational Institutions nearby',
        image: HighlightImage2
      },
      {
        title: 'Quality healthcare with Narayana hospitals nearby',
        image: HighlightImage3
      },
      {
        title: 'Great Connectivity to STRR, NH44 and upcoming NH844',
        image: HighlightImage4
      },
    ];
  
    return (
      <section className="relative w-full py-12 bg-gray-100" style={{ backgroundImage: `url(${BackgroundImg})` }}>
        <div className="container mx-auto px-4 lg:px-8">
          <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-8 text-center">
            Key Highlights
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {highlights.map((highlight, index) => (
              <div key={index} className="flex items-center overflow-hidden">
                <div className="w-1/3">
                  <img
                    src={highlight.image}
                    alt={`Highlight ${index + 1}`}
                    className="w-full h-24 object-contain"
                  />
                </div>
                <div className="w-2/3 p-4">
                  <p className="text-sm lg:text-base font-medium text-gray-700">{highlight.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  export default KeyHighlights;