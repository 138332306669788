import React from 'react';
import Image from '../assets/home/prjct-overview.jpg'; // Replace with the path to your image
import { LuDownload } from "react-icons/lu";

const ProjectOverview = () => {
  return (
    <section id="project-overview" className="relative w-full py-16 lg:py-24">
      <div className="container mx-auto px-4 lg:px-8 flex flex-col lg:flex-row items-center">
        {/* Image Section */}
        <div className="lg:w-1/2 w-full">
          <img
            src={Image}
            alt="Project Overview"
            className="w-full h-auto object-cover rounded-lg shadow-lg"
          />
        </div>
        {/* Content Section */}
        <div className="lg:w-1/2 w-full lg:pl-12 mt-8 lg:mt-0">
          <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-800 mb-6">
            Project Overview
          </h2>
          <div className="text-gray-700 mb-8">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">Type</h3>
            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="font-medium">30x40</span>
                <span className="font-medium">1,200 SQ.FT.</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">40x30</span>
                <span className="font-medium">1,200 SQ.FT.</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">30x50</span>
                <span className="font-medium">1,500 SQ.FT.</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Odd Size</span>
              </div>
            </div>
          </div>
          <div className="text-gray-700 mb-8">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">Testimonials</h3>
            <blockquote className="border-l-4 border-indigo-600 pl-4 italic">
              <p>"This project has been a game-changer for our company. The design and execution were top-notch, and we've seen fantastic results!"</p>
              <footer className="mt-2 text-gray-600">— Jane Doe, CEO of Company</footer>
            </blockquote>
          </div>
          {/* Call to Action */}
          <div className="inline-flex items-center px-6 py-3 bg-indigo-600 text-white rounded-lg shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300 ease-in-out mb-8">
            <span>Download Brochure</span>
            <LuDownload className='ml-2'/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectOverview;
