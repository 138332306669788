// App.js
import './App.css';
import Amenities from './components/Amenities';
import CompletedProjects from './components/CompletedProjects';
import ContactForm from './components/Footer';
import Gallery from './components/Gallery';
import Header from './components/Header';
import KeyHighlights from './components/KeyHighlights';
import LocationMap from './components/LocationMap';
import Masterplan from './components/Masterplan';
import ProjectOverview from './components/ProjectOverview';
import SliderBanner from './components/SliderBanner';
import UpcomingProjects from './components/UpcomingProjects';
import WhyGreenOrchid from './components/WhyGreenOrchid';

function App() {
  return (
    <div className="App">
      <Header />
      <SliderBanner />
      <section id="why-green-orchid">
        <WhyGreenOrchid />
      </section>
      <section id="project-overview">
        <ProjectOverview />
      </section>
      <section id="upcoming-projects">
        <UpcomingProjects />
      </section>
      <section id="completed-projects">
        <CompletedProjects />
      </section>
      <section id="key-highlights">
        <KeyHighlights />
      </section>
      <section id="masterplan">
        <Masterplan />
      </section>
      <section id="amenities">
        <Amenities />
      </section>
      <section id="gallery">
        <Gallery />
      </section>
      <section id="location-map">
        <LocationMap />
      </section>
      <ContactForm />
    </div>
  );
}

export default App;
