import React from 'react';

const LocationMap = () => {
    return (
        <section id='location-map' className="relative w-full py-12 bg-gray-100">
            <div className="container mx-auto px-4 lg:px-8">
                {/* Introductory Content */}
                <div className="text-center mb-12">
                    <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-8">
                        Location Map
                    </h2>
                    <p className="text-base lg:text-lg text-gray-700 mb-8">
                        Our project stands out due to its exceptional location, offering easy access to top-notch schools, vibrant shopping districts, and essential healthcare facilities. Immerse yourself in the serenity of beautifully landscaped gardens and lush green spaces, while enjoying seamless connectivity to major routes such as the Main Highway, the Central Business District Expressway, and the forthcoming North-South Corridor. With the nearby Metro station just a short 10-minute drive away, Harmony Heights is where modern living meets unparalleled convenience.
                    </p>

                </div>

                {/* Map Embed */}
                <div className="w-full h-[400px] rounded-lg overflow-hidden">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.328227593942!2d-89.64882578461426!3d39.78172115818696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8807b5f3c4b5e7cf%3A0x6b99e9db4e663c!2s123%20Elm%20St%2C%20Springfield%2C%20IL%2062704%2C%20USA!5e0!3m2!1sen!2sin!4v1635564372900!5m2!1sen!2sin"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Location Map"
                    ></iframe>

                </div>
            </div>
        </section>
    );
};

export default LocationMap;
