import React from 'react';
import Image1 from '../assets/home/best-properties-1.jpg'; // Example images
import Image2 from '../assets/home/ECOTOWN_HOUSES.jpg';
import Image3 from '../assets/home/SKYLINE.jpg';

const UpcomingPlotProjects = () => {
  // Updated data for plot projects
  const plotProjects = [
    {
      title: 'Tech City Development',
      description: 'A large-scale development with numerous plots for residential and commercial use.',
      image: Image1,
      location: 'Tech City',
      plots: 150,
      totalSqFeet: '100,000',
      year: 2024,
    },
    {
      title: 'Greenfield Estates',
      description: 'An eco-friendly community with a focus on sustainability and green spaces.',
      image: Image2,
      location: 'Greenfield',
      plots: 75,
      totalSqFeet: '60,000',
      year: 2025,
    },
    {
      title: 'Downtown Heights Expansion',
      description: 'A prime location expansion with plots suitable for mixed-use development.',
      image: Image3,
      location: 'Downtown Heights',
      plots: 90,
      totalSqFeet: '85,000',
      year: 2026,
    },
    // Duplicate entries for demo purposes
    {
      title: 'Tech City Development',
      description: 'A large-scale development with numerous plots for residential and commercial use.',
      image: Image1,
      location: 'Tech City',
      plots: 150,
      totalSqFeet: '100,000',
      year: 2024,
    },
    {
      title: 'Greenfield Estates',
      description: 'An eco-friendly community with a focus on sustainability and green spaces.',
      image: Image2,
      location: 'Greenfield',
      plots: 75,
      totalSqFeet: '60,000',
      year: 2025,
    },
    {
      title: 'Downtown Heights Expansion',
      description: 'A prime location expansion with plots suitable for mixed-use development.',
      image: Image3,
      location: 'Downtown Heights',
      plots: 90,
      totalSqFeet: '85,000',
      year: 2026,
    },
  ];

  return (
    <section id="upcoming-plot-projects" className="bg-gray-50 py-16 lg:py-24">
      <div className="container mx-auto px-4 lg:px-8">
        <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-800 mb-12 text-center">
          Upcoming Plot Projects
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {plotProjects.map((project, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-72 object-cover rounded-t-lg mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-800 mb-2">{project.title}</h3>
              <p className="text-gray-600 mb-4">{project.description}</p>
              <div className="text-gray-700 mb-4">
                <p><strong>Location:</strong> {project.location}</p>
                <p><strong>No of Plots:</strong> {project.plots}</p>
                <p><strong>Total Sq Feet:</strong> {project.totalSqFeet}</p>
                <p><strong>Year:</strong> {project.year}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UpcomingPlotProjects;
