import React from 'react';
import LogoImage from '../assets/home/footer-logo1.png'; // Replace with the path to your logo image
import InstagramIcon from '../assets/home/instagram-hover.svg'; // Replace with the path to your Instagram icon
import FacebookIcon from '../assets/home/facebook-hover.svg'; // Replace with the path to your Facebook icon
import YouTubeIcon from '../assets/home/youtube-hover.svg'; // Replace with the path to your YouTube icon

const Footer = () => {
    return (
        <section className="relative w-full py-12">
            <div className="container mx-auto px-4 lg:px-8 flex flex-col lg:flex-row gap-8">

                {/* Form Section */}
                <div className="w-full lg:w-1/2 bg-white p-8">
                    <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-6">
                        Contact Us
                    </h2>
                    <form action="#" method="POST">
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">
                                Name *
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="w-full p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                placeholder="Enter your name"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
                                Email *
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="w-full p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                placeholder="Enter your email"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="phone" className="block text-gray-700 font-medium mb-2">
                                Phone Number *
                            </label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                className="w-full p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                placeholder="Enter your phone number"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="query" className="block text-gray-700 font-medium mb-2">
                                Query *
                            </label>
                            <textarea
                                id="query"
                                name="query"
                                rows="4"
                                className="w-full p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                placeholder="Enter your query"
                                required
                            ></textarea>
                        </div>
                        <div className="text-center">
                            <button
                                type="submit"
                                className="inline-flex items-center px-6 py-3 bg-indigo-600 text-white rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300 ease-in-out text-sm lg:text-base"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>

                {/* Contact Information Section */}
                <div className="w-full lg:w-1/2 lg:pl-8 mt-12 lg:mt-0 flex flex-col">
                    <div className="flex items-center mb-2">
                        <img
                            src={LogoImage}
                            alt="Company Logo"
                            className="w-32 h-auto object-contain"
                        />
                        <div className="ml-6 flex space-x-4">
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                <img src={InstagramIcon} alt="Instagram" className="w-8 h-8 hover:scale-110 transition-transform duration-300" />
                            </a>
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <img src={FacebookIcon} alt="Facebook" className="w-8 h-8 hover:scale-110 transition-transform duration-300" />
                            </a>
                            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                                <img src={YouTubeIcon} alt="YouTube" className="w-8 h-8 hover:scale-110 transition-transform duration-300" />
                            </a>
                        </div>
                    </div>
                    <div className="text-gray-700 mb-2 text-xs md:text-base">
                        <p className="mb-4">
                            <span className="font-bold text-sm md:text-base">Site Address:</span> 789 Pine Road, Riverview, Orlando, FL 32801.
                        </p>
                        <p className="mb-4 text-xs md:text-base">
                            <span className="font-bold text-sm md:text-base">Office Address:</span> 1010 Maple Lane, Suite 205, Downtown, Miami, FL 33101.
                        </p>

                        <p className="mb-4 text-xs md:text-base">
                            <span className="font-bold text-sm md:text-base">Call Us:</span> +91 91347 47474
                        </p>
                    </div>
                    <div className="text-gray-700">
                        <h3 className="font-bold text-sm md:text-base mb-2">Disclaimer:</h3>
                        <p className='text-xs md:text-base'>
                            The information and material contained or referred to in this document are for reference only. Century Real Estate Holdings Pvt. Ltd. makes no representation or warranty of any kind express, implied or statutory regarding this report or the materials and information contained or referred to on each page associated with this report. The information and materials contained in this report are subject to change without notice, are provided for general information only and should not be used as a basis for making business decisions. Any information or advice received via this document should not be relied upon without consulting a primary or more accurate or more up-to-date source of information or specific professional advice. All images in this document are for representational purposes only.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;
